<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'" :subtitle="'Cadastrar novo número'" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12 info-header">
          <h4>Números cadastrados</h4>
          <p>
            Tenha todas as informações de seus números cadastrados na plataforma Meta Gerenciador do WhatsAPP. Cadastre um novo número de forma fácil, rápida e conveniente.
          </p>
        </div>
        <div class="col-lg-12 col-xl-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <a class="nav-link router-link-exact-active router-link-active">
                    <span class="material-symbols-outlined"> settings_phone </span>Meus números cadastrados
                  </a>
                </li>
                <div class="opts_card">
                  <a v-b-toggle.sidebar-right-channel class="btn-add">
                    <span>Cadastrar novo número</span> <span class="material-symbols-outlined" style="font-size: 18px !important;">add_circle </span>
                  </a>
                </div>
              </ul>
            </div>
            <div class="card-body block-el">
              <div class="responsive-table">
                <div class="loading min-h-300" v-if="loading">
                  <LoadingAnim />
                </div>
                <div class="table" v-else>
                  <table>
                    <thead role="row">
                      <tr>
                      <th role="columnheader" scope="col">Telefone</th>
                      <th role="columnheader" scope="col">Nome</th>
                      <th role="columnheader" scope="col">Limite de mensagens</th>
                      <th role="columnheader" scope="col">Classificação</th>
                      <th role="columnheader" scope="col">Status</th>
                      <th role="columnheader" scope="col"></th>
                    </tr>
                    </thead>
                    <tbody role="rowgroup">
                    <tr  role="row" v-for="(number, i) in numbers" :key="i">
                      <td data-label="Telefone">{{number.phone_number}}</td>
                      <td data-label="Nome">
                        <svg width="25" class="hide" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.8008 12.228L21.3608 9.43803L21.7008 5.74803L18.0908 4.92803L16.2008 1.72803L12.8008 3.18803L9.40078 1.72803L7.51078 4.91803L3.90078 5.72803L4.24078 9.42803L1.80078 12.228L4.24078 15.018L3.90078 18.718L7.51078 19.538L9.40078 22.728L12.8008 21.258L16.2008 22.718L18.0908 19.528L21.7008 18.708L21.3608 15.018L23.8008 12.228ZM10.1808 16.238L7.80078 13.838C7.41078 13.448 7.41078 12.818 7.80078 12.428L7.87078 12.358C8.26078 11.968 8.90078 11.968 9.29078 12.358L10.9008 13.978L16.0508 8.81803C16.4408 8.42803 17.0808 8.42803 17.4708 8.81803L17.5408 8.88803C17.9308 9.27803 17.9308 9.90803 17.5408 10.298L11.6208 16.238C11.2108 16.628 10.5808 16.628 10.1808 16.238Z" fill="#16CD08"/>
                        </svg>
                        {{number.name}}
                      </td>
                      <td data-label="Limite de mensagens">
                        <span v-if="number.current_limit == 'TIER_1K'">Mil clientes/24 horas</span>
                        <span v-else-if="number.current_limit == 'TIER_10K'">10 Mil clientes/24 horas</span>
                        <span v-else-if="number.current_limit == 'TIER_100K'">100 Mil clientes/24 horas</span>
                        <span v-else-if="number.current_limit == 'TIER_0.25K'">250 Mil clientes/24 horas</span>
                        <span v-else>Ilimitado</span>
                      </td>
                      <td data-label="Classificação">
                        <span class="badge badge-success" v-if="number.current_quality_rating == 'High'">Alto</span>
                        <span class="badge badge-danger" v-if="number.current_quality_rating == 'Low'">Baixo</span>
                        <span class="badge badge-warning" v-if="number.current_quality_rating == 'Medium'">Médio</span>
                      </td>
                      <td data-label="Status">
                        <span class="badge badge-success">Conectado</span>
                      </td>
                      <td data-label="">
                        <span class="material-symbols-outlined icon-red c-pointer" @click="removeNumber(number.phone_identification)">delete</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="no-item-list text-center" v-if="numbers.length == 0 && !loading">
                  <span class="material-symbols-outlined">settings_phone </span>
                  <span>Nenhum número cadastrado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
      class="relative"
      id="sidebar-right-channel"
      :title="$t('catalog.list.edit')"
      no-header
      right
      backdrop
      shadow
    >
      <div class="col sidebar-header">
        <p>Cadastrar</p>
        <span
          v-b-toggle.sidebar-right-channel
          class="material-symbols-outlined sidebar-close"
          >close</span
        >
      </div>
      <div class="col desc-column">
        <p>
          Enriqueça seu atendimento no aplicativo de mensagens mais popular do mundo. Ofereça suporte imediato e uma experiência personalizada aos seus clientes com o WhatsApp Business API.
        </p>
      </div>
      <div>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          @click="metMessage"
          style="display: flex"
          >Cadastrar novo número
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </a>
      </div>
    </b-sidebar>
    <active-whats-modal
      id="active-whats-modal"
      @send="checkMessenger"
    ></active-whats-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Tab from '@/directives/Tab';
import axios from '@/plugins/axios';
import ActiveWhatsModal from '@/components/ActiveWhatsModal.vue';
import Swal from 'sweetalert2';
import ChannelService from '@/services/channel.service';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

const webApi = axios();

export default {
  name: 'WhatsappDashboard',
  components: {
    PageHeader,
    ActiveWhatsModal,
    LoadingAnim,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      whatsId: process.env.VUE_APP_WHATS_ID,
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/reports/history',
      },
      optionCalendar: {
        autoApply: true,
        startDate: moment().format('YYYY-MM-DD'),
      },
      numbers: [],
      loading: true,
      filter: {
        status: [],
        from: '',
        to: '',
        campaign: '',
        page: 1,
      },
      campaigns: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          status: [],
          from: '',
          to: '',
          campaign: '',
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
          page: 1,
        },
      },
    };
  },
  created() {
    this.getNumbers();
  },
  computed: {
    whatsRouter() {
      let redirectUri = 'https://dev.yup.chat/whatsapp/webhook-business';

      if (window.location.host.indexOf('app.') === 0) {
        redirectUri = 'https://app.yup.chat/whatsapp/webhook-business';
      }

      const url = `https://www.facebook.com/dialog/oauth?response_type=code&display=popup&app_id=571156307528909&redirect_uri=${encodeURIComponent(
        redirectUri,
      )}&scope=business_management,whatsapp_business_management&state=${encodeURIComponent(
        this.$store.state.account.id,
      )}&display=dialog`;
      return url;
    },
  },
  // Pai
  methods: {
    removeNumber(number) {
      Swal.fire({
        title: 'Remover número',
        text: 'Tem certeza que gostaria de remover esse número?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.loadPage = true;
          webApi.delete(`whatsapp/new/numbers/${number}`).then(
            (response) => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Número removido',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
              this.flowLoading = false;
            },
          );
        }
      });
    },
    metMessage() {
      Swal.fire({
        title: 'Você tem uma conta business?',
        text: 'Tem uma conta business no facebook?',
        icon: 'warning',
        buttons: true,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then((result) => {
        if (result.dismiss === 'cancel') {
          window.location.href = this.whatsRouter;
        }
        if (result.value) {
          this.$root.$emit('show.modal', 'active-whats-modal');
        }
      });
    },
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    reload() {
      this.$refs.view.fetch(1);
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    checkMessenger() {
      ChannelService.webhookMessenger({
        channel_id: this.whatsId,
        channel_live_id: process.env.VUE_APP_WHATS_LIVE_ID,
        account_identification: this.$route.query.code,
        external_id: this.$route.query.code,
        end_point_send: 'http://localhost:8097/send/messenger',
      })
        .then(
          (send) => {
            this.loadPage = false;
            Swal.fire('Ativado', 'Canal ativado com sucesso', 'success');
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    getNumbers() {
      webApi.get('whatsapp/new/numbers').then((response) => {
        this.loading = false;
        response.data.forEach((data) => {
          if (data.deleted === false) {
            this.numbers.push(data);
          }
        });
      }, (error) => {
        this.content = error;
        this.loading = false;
      });
    },
    cleanFilter() {
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign: '',
        page: 1,
      };
      this.form.filter = {};
      this.reload();
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.form.filters.gte = date[0];
        this.form.filters.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
.card {
  box-shadow: none !important;
}
.nav-tabs {
  border-bottom: 4px solid #6B23DC3D;
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  border-bottom: solid 4px var(--clr-yup-purple);
}
.nav-tabs .nav-item {
  margin-bottom: -4px;
}
::v-deep .icon-calendar {
  color: var(--gray-font-color) !important;
}
.btn-add {
  display: flex;
}
.btn-add span{
  color: #6B23DC;
  font-size: 16px !important;
  cursor: pointer;
  display: block;
}
.btn-add .material-symbols-outlined {
  margin-top: 4px;
}
table {
  width: 100%;
  border-spacing: 0 12px;
  border-collapse: separate;
  margin: -24px 0;
  table-layout: fixed;

  thead {
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      // border-bottom: color-mix(in srgb, var(--clr-yup-purple) 20%, transparent) 4px solid;
      border-bottom: none;
    }
  }

  tbody {
    tr {

      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #E5E5E5;
        padding: 16px;
        .material-symbols-outlined {
          vertical-align: text-bottom;
        }
      }

      td:first-child {
        border-left: 1px solid #E5E5E5;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-right: 1px solid #E5E5E5;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}
.icon-red {
  color: #AD1519;
}
.info-header h4 {
  font-size: 20px;
  color: #101828;
  font-weight: 400;
}
.info-header p {
  color: #595959;
  font-weight: 400;
}
::v-deep #sidebar-right-channel {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    > strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.btn-card-open-borderd {
  background: #fff;
  border: none;
  color: white;
  width: 80%;
  padding: 5px;
  border-radius: 8px;
  border: solid 1px #7e38ef;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}
.btn-card-open-borderd {
  color: #7e38ef;
}
.btn-card-open-borderd i {
  color: #7e38ef;
}
.btn-add-new-account {
  color: #7e38ef !important;
  padding: 10px;
  padding-left: 40px;
  width: 60%;
  margin-bottom: 30px;
}
.btn-add-new-account span {
  color: #7e38ef;
}
.btn-add-new-account {
  transition: all 0.2s;
}
.btn-add-new-account:hover {
  background: #7e38ef;
}

.btn-add-new-account:hover span {
  color: #fff;
}
.btn-add-new-account:hover {
  color: #fff !important;
}
.btn-add-new-account:hover {
  padding-left: 43px;
}
@media (max-width: 666px) {
  .nav-link {
    display: none !important;
  }
}
.icon-red:hover {
  color: #831417;
}
.no-item-list span {
  display: block;
}
.no-item-list .material-symbols-outlined {
  display: block;
  font-size: 40px;
  color: #a7a7a7;
}
</style>
